import { IonButton, IonCol, IonModal, IonRow, useIonLoading } from '@ionic/react';

import { useCapturePostHog } from '../../hooks/useCapturePostHog';
import type { ReportedChatBy } from '../../pages/advocate/AdvocateChatSlice';
import { saveAdvocateReportedChatBy, setOpenReportModal } from '../../pages/advocate/AdvocateChatSlice';
import { useAppDispatch, useAppSelector } from '../../store';

export const ReportChatModal = () => {
  const dispatch = useAppDispatch();
  const { capturePostHogCustomEvent } = useCapturePostHog();
  const [presentLoading, dismissLoading] = useIonLoading();
  const openReportModal = useAppSelector((state) => state.advocateSlice.openReportModal);
  const advocateChatUserConn = useAppSelector((state) => state.advocateSlice.advocateChatUserConn);

  const close = () => {
    capturePostHogCustomEvent('ReportChatModal dismissed report chat modal');
    dispatch(setOpenReportModal(false));
  };

  const report = async (reportedBy: ReportedChatBy) => {
    if (advocateChatUserConn?.threadId) {
      presentLoading();
      await dispatch(
        saveAdvocateReportedChatBy({
          thread_id: advocateChatUserConn.threadId,
          reported_by_advocate: reportedBy,
        }),
      );
      capturePostHogCustomEvent('ReportChatModal advocate reported chat', {
        reason: reportedBy,
        thread_id: advocateChatUserConn.threadId,
      });
      dismissLoading();
    }
    close();
  };

  return (
    <IonModal id="report-abuse-modal" isOpen={openReportModal} onDidDismiss={close}>
      <IonRow className="ion-text-center ion-padding text-content">
        <IonCol size="12" className="headline-medium">
          Report chat
        </IonCol>
        <IonCol size="12">You should report abusive behaviour and languages.</IonCol>
        <IonCol size="12">You may also report chats for emergency purposes.</IonCol>
        <IonCol size="12">Chat logs will be preserved and reviewed by Talking Stick program administers.</IonCol>
      </IonRow>

      <IonRow className="ion-padding-horizontal">
        <IonCol className="ion-no-padding">
          <IonButton
            className="defaultButtonRed"
            mode="ios"
            expand="block"
            shape="round"
            size="small"
            onClick={() => report('Emergency')}
          >
            Report emergency
          </IonButton>
        </IonCol>
        <IonCol className="ion-no-padding">
          <IonButton
            className="defaultButtonRed"
            mode="ios"
            expand="block"
            shape="round"
            size="small"
            onClick={() => report('Abuse')}
          >
            Report abuse
          </IonButton>
        </IonCol>
      </IonRow>
      <IonRow className="ion-justify-content-center">
        <IonCol size="6">
          <IonButton className="defaultButtonBlue" mode="ios" expand="block" shape="round" size="small" onClick={close}>
            Cancel
          </IonButton>
        </IonCol>
      </IonRow>
    </IonModal>
  );
};
