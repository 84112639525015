import { useEffect } from 'react';

import { IonButton, IonCol, IonItem, IonLabel, IonRadio, IonRadioGroup, IonRow, useIonToast } from '@ionic/react';

import { ADVOCATE_TOAST_OPTIONS } from '../../../constants';
import { useCapturePostHog } from '../../../hooks/useCapturePostHog';
import { ADVOCATE_COMPONENT, setAdvocatePostChatSurvey } from '../../../pages/advocate/AdvocateChatSlice';
import { getAdvocateEndReasons, setAdvocateChatPageStatus } from '../../../pages/advocate/AdvocateChatSlice';
import { useAppDispatch, useAppSelector } from '../../../store';
import { AdvocatePostChatSurveyContainer } from './AdvocatePostChatSurveyContainer';

export const AdvocateChatSessionEndedPart2 = () => {
  const { capturePostHogCustomEvent } = useCapturePostHog();
  const [present] = useIonToast();
  const dispatch = useAppDispatch();
  const advocatePostChatSurvey = useAppSelector((state) => state.advocateSlice.advocatePostChatSurvey);

  useEffect(() => {
    dispatch(getAdvocateEndReasons());
  }, [dispatch]);

  return (
    <AdvocatePostChatSurveyContainer>
      <IonRow>
        <IonCol className="ion-padding-vertical ion-text-center headline-medium">Chat session ended</IonCol>
      </IonRow>
      <IonRow>
        <IonCol className="ion-text-center">
          <IonItem lines="none" mode="ios">
            <IonLabel className="title-medium" position="stacked">
              2. Did you refer this guest to any other services/support (ex phone number, website, email, etc)?
              (required)
            </IonLabel>
            <IonRadioGroup
              value={advocatePostChatSurvey.referred_guest}
              onIonChange={(e) => {
                dispatch(setAdvocatePostChatSurvey({ referred_guest: e.detail.value }));
                capturePostHogCustomEvent('AdvocateChatSessionEndedPart2Component answered question on guest referral');
              }}
            >
              <IonItem lines="none">
                <IonRadio color="secondary" mode="md" slot="start" value={true} labelPlacement="end">
                  <IonLabel>Yes</IonLabel>
                </IonRadio>
              </IonItem>
              <IonItem lines="none">
                <IonRadio color="secondary" mode="md" slot="start" value={false} labelPlacement="end">
                  <IonLabel>No</IonLabel>
                </IonRadio>
              </IonItem>
            </IonRadioGroup>
          </IonItem>
        </IonCol>
      </IonRow>
      <IonRow className="ion-justify-content-center">
        <IonCol size="6" className="ion-padding-vertical">
          <IonButton
            className="defaultButtonBlue"
            mode="ios"
            expand="block"
            shape="round"
            onClick={() => {
              if (advocatePostChatSurvey.referred_guest != null) {
                dispatch(setAdvocateChatPageStatus(ADVOCATE_COMPONENT.POST_CHAT_SURVEY_3));
                capturePostHogCustomEvent('AdvocateChatSessionEndedPart2Component entered part 3 of the survey');
              } else {
                present({ ...ADVOCATE_TOAST_OPTIONS, message: 'Please specify whether or not you made a referral.' });
                capturePostHogCustomEvent(
                  'AdvocateChatSessionEndedPart2Component encountered a submission error on guest referral',
                );
              }
            }}
          >
            Next
          </IonButton>
        </IonCol>
      </IonRow>
    </AdvocatePostChatSurveyContainer>
  );
};
