import type { EnglishTranslation } from '../i18n';

export type Advocate = {
  advocate_id: string;
  available: boolean;
  name: string;
  img_url: string;
  languages: string[];
  community: string;
  community_id: string;
  // LEPS
  lived_experiences?: (keyof EnglishTranslation['labels'])[];
  // Service provider
  service_provider_organization_id?: string;
  service_provider_organization_name?: string;
  service_provider_title?: string;
  service_title?: string;
  service_description?: string;
};

export class Profile {
  first_name: string | null = null;
  last_name: string | null = null;
  nickname: string | null = null;
  email = '';
  community_id: string | null = null;
  label_ids: string[] = [];
  language_codes: string[] = [];
  languages: string[] = [];
  community = '';
  id = '';
  img_url = '';
  // Service provider
  service_provider_organization_id?: string;
  service_provider_title?: string | null = null;
  service_title?: string | null = null;
  service_description?: string | null = null;
  service_provider_organization_name: string | null = null;
  service_communities?: string[] | null = null;
  service_community_ids?: string[] | null = null;
}

export type Label = {
  alias: string;
  id: string;
  name: string;
  order: string;
};

export class AdvocatePostChatSurvey {
  thread_id: string | null = null;
  end_reason_id: string | null = null;
  comments: string | null = null;
  referred_guest: boolean | null = null;
  subject_ids: string[] = [];
  other_subject: string | null = null;
  is_training = false;
  abuse_rating: number | null = null;
}

export type SessionLog = {
  month: number | undefined;
  year: number | undefined;
};

export type SessionLogResponse = {
  advocate_post_chat_survey_complete: boolean;
  community: string;
  community_id: string;
  email: string;
  end_timestamp: string;
  first_name: string;
  id: string;
  last_name: string;
  start_timestamp: string;
  trigger_timestamp: string;
  thread_id: string;
  time_expired: boolean;
};

export type AdvocateHoursResponse = {
  time_online: string;
};
