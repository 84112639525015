import { useEffect, useState } from 'react';
import type { ReactNode } from 'react';

import { refreshToken, updateAuth } from '../pages/advocate/AdvocateAuthSlice';
import { getWebUserAsync } from '../services/login';
import { useAppDispatch } from '../store';

export const AuthPersist = ({ children }: { children: ReactNode }) => {
  const dispatch = useAppDispatch();
  const [ready, setReady] = useState(false);

  useEffect(() => {
    const getPersistedState = async () => {
      const storedAuthResult = await getWebUserAsync();

      if (storedAuthResult?.token) {
        dispatch(updateAuth(storedAuthResult));
        await dispatch(refreshToken());
      }

      setReady(true);
    };

    getPersistedState();
  }, [dispatch]);

  if (!ready) {
    return null;
  }

  return <>{children}</>;
};
