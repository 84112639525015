import { useEffect } from 'react';
import type { Properties } from 'posthog-js';
import { usePostHog } from 'posthog-js/react';
import { useLocation } from 'react-router';

import { useAppSelector } from '../store';

const getPage = (pathname: string) => {
  const parts = pathname.split('/');
  return ['advocate', 'guest'].includes(parts[1]) ? `${parts[1]}-${parts[2]}` : (parts[1] ?? parts[0]);
};

export const useCapturePostHogPageView = () => {
  const location = useLocation();
  const posthog = usePostHog();
  const token = useAppSelector((state) => state.advocateAuthSlice.authResult.token);
  const page = getPage(location.pathname);

  useEffect(() => {
    const properties: Properties = {
      role: token ? 'Advocate' : 'Guest',
    };
    posthog?.capture(`Entered ${page} page`, properties);
    return () => {
      posthog?.capture(`Left ${page} page`, properties);
    };
  }, [page, posthog, token]);
};
