import type { AxiosError } from 'axios';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { api } from '../../api';
import { environment } from '../../environment/environment';
import type { Advocate } from '../../models/advocate';
import { GUEST_COMPONENT } from '../../models/chat';
import type { ErrorMessage } from '../../models/Error';
import type { Community, FindAdvocates } from '../../models/guest';
import { FilterModalStatus } from '../../models/guest';
import type { Language } from '../../models/language';
import type { AsyncThunkConfig } from '../../models/slice';
import { RaygunErrorHandlerService } from '../../services/raygun';

const { logError } = RaygunErrorHandlerService();

type LepsSliceType = {
  lepsPageStatus: GUEST_COMPONENT;
  lepsAdvocates: Advocate[];
  selectedLepsAdvocate: Advocate | undefined;
  findLepsAdvocates: FindAdvocates;
  lepsSelectedCommunityList: Community[];
  lepsSelectedLanguageList: Language[];
  lepsFilterModalStatus: FilterModalStatus;
  searchLepsAdvocatesLoading: boolean;
  showLepsTabBar: boolean;
};

const initialState: LepsSliceType = {
  lepsPageStatus: GUEST_COMPONENT.ADVOCATE_SELECT,
  selectedLepsAdvocate: undefined,
  findLepsAdvocates: {
    community_ids: [],
    languages: [],
  },
  lepsSelectedCommunityList: [],
  lepsSelectedLanguageList: [],
  lepsAdvocates: [],
  lepsFilterModalStatus: FilterModalStatus.IDLE,
  searchLepsAdvocatesLoading: false,
  showLepsTabBar: true,
};

export type FindAdvocatesBody = {
  community_ids: string[];
  languages: string[];
  for_service_providers: boolean;
  elm_app_major_version: string;
  elm_app_version: string;
};

export const searchLepsAdvocates = createAsyncThunk<Advocate[], undefined, AsyncThunkConfig>(
  'leps/searchLepsAdvocates',
  async (_, thunkAPI) => {
    try {
      const findAdvocates = thunkAPI.getState().lepsSlice.findLepsAdvocates;
      const body: FindAdvocatesBody = {
        ...findAdvocates,
        for_service_providers: false,
        elm_app_major_version: environment.versionNumber.split('.')[0],
        elm_app_version: environment.versionNumber,
      };
      const response = await api.post<Advocate[]>('/guest/v1_search_advocates', body);
      return response ?? [];
    } catch (e) {
      logError(e, ['lepsSlice', 'searchLepsAdvocates']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const lepsSlice = createSlice({
  name: 'leps',
  initialState,
  reducers: {
    setLepsPageStatus: (state, action: PayloadAction<GUEST_COMPONENT>) => {
      state.lepsPageStatus = action.payload;
    },
    setSelectedLepsAdvocate: (state, action: PayloadAction<Advocate | undefined>) => {
      state.selectedLepsAdvocate = action.payload;
    },
    setFindLepsAdvocates: (state, action: PayloadAction<Partial<FindAdvocates>>) => {
      state.findLepsAdvocates = { ...state.findLepsAdvocates, ...action.payload };
    },
    setLepsSelectedCommunityList: (state, action: PayloadAction<Community[]>) => {
      state.lepsSelectedCommunityList = action.payload;
    },
    setLepsSelectedLanguageList: (state, action: PayloadAction<Language[]>) => {
      state.lepsSelectedLanguageList = action.payload;
    },
    setLepsFilterModalStatus: (state, action: PayloadAction<FilterModalStatus>) => {
      state.lepsFilterModalStatus = action.payload;
    },
    setShowLepsTabBar: (state, action: PayloadAction<boolean>) => {
      state.showLepsTabBar = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchLepsAdvocates.pending, (state, _action) => {
        state.searchLepsAdvocatesLoading = true;
      })
      .addCase(searchLepsAdvocates.fulfilled, (state, action) => {
        state.lepsAdvocates = action.payload;
        state.searchLepsAdvocatesLoading = false;
      });
  },
});

export const {
  setLepsPageStatus,
  setSelectedLepsAdvocate,
  setFindLepsAdvocates,
  setLepsSelectedCommunityList,
  setLepsSelectedLanguageList,
  setLepsFilterModalStatus,
  setShowLepsTabBar,
} = lepsSlice.actions;
