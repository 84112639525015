import type { AxiosError } from 'axios';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { api } from '../../api';
import type { Org } from '../../helpers/device.helper';
import type { ErrorMessage } from '../../models/Error';
import type { AsyncThunkConfig } from '../../models/slice';
import { decodeToken, saveWebUser } from '../../services/login';
import { RaygunErrorHandlerService } from '../../services/raygun';

const { logError, setRaygunUser } = RaygunErrorHandlerService();

export type LoginParams = {
  email: string;
  pass: string;
};

export type ForgotPasswordParams = {
  email: string;
};

export type ResetPasswordParams = {
  pass: string;
  retype_pass: string;
};

export type ChangePasswordParams = {
  email: string;
  old_pass: string;
  new_pass: string;
  pass_confirm: string;
};

export type PasswordVisibilityState = {
  oldPassword?: boolean;
  newPassword: boolean;
  retypePassword: boolean;
};

export class AuthResult {
  first_name: string | null = null;
  internal = false;
  token: string | null = null;
  // FIXME: This is a temporary solution until we add the service_provider_organization_id
  // to the token data to be used in authSlice and currentuser
  service_provider: boolean | null = null;
}

export type TokenValue = {
  user_id: string;
  role: string;
  org_code: Org;
  service_provider_organization_id: string;
};

type AdvocateAuthSliceType = {
  authResult: AuthResult;
  currentUser: TokenValue | undefined;
};

const initialState: AdvocateAuthSliceType = {
  authResult: new AuthResult(),
  currentUser: undefined,
};

export const login = createAsyncThunk<AuthResult, LoginParams, AsyncThunkConfig>(
  'advocateAuth/login',
  async (login, thunkAPI) => {
    try {
      const response = await api.post<AuthResult>('/advocate/v0_login', login);
      if (response?.token) {
        await saveWebUser(response);
      }
      return response ?? new AuthResult();
    } catch (e) {
      logError(e, ['advocateAuthSlice', 'login']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const refreshToken = createAsyncThunk<AuthResult, undefined, AsyncThunkConfig>(
  'advocateAuth/refreshToken',
  async (_, thunkAPI) => {
    try {
      const response = await api.post<AuthResult>('/advocate/v0_refresh_token');
      if (response?.token) {
        await saveWebUser(response);
      }
      return response ?? new AuthResult();
    } catch (e) {
      logError(e, ['advocateAuthSlice', 'refreshToken']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const forgotPassword = createAsyncThunk<boolean | undefined, ForgotPasswordParams, AsyncThunkConfig>(
  'advocateAuth/forgotPassword',
  async (forgotPasswordParams, thunkAPI) => {
    try {
      const response = await api.post<boolean>('/advocate/v0_forgot_password', forgotPasswordParams);
      return response;
    } catch (e) {
      logError(e, ['advocateAuthSlice', 'forgotPassword']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const resetPassword = createAsyncThunk<AuthResult, ResetPasswordParams, AsyncThunkConfig>(
  'advocateAuth/resetPassword',
  async (resetPasswordParams, thunkAPI) => {
    try {
      const response = await api.post<AuthResult>('/advocate/v0_reset_password', resetPasswordParams);
      if (response?.token) {
        await saveWebUser(response);
      }
      return response ?? new AuthResult();
    } catch (e) {
      logError(e, ['advocateAuthSlice', 'resetPassword']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const changePassword = createAsyncThunk<AuthResult, ChangePasswordParams, AsyncThunkConfig>(
  'advocateAuth/changePassword',
  async (changePasswordParams, thunkAPI) => {
    try {
      const response = await api.post<AuthResult>('/advocate/v0_change_password', changePasswordParams);
      if (response?.token) {
        await saveWebUser(response);
      }
      return response ?? new AuthResult();
    } catch (e) {
      logError(e, ['advocateAuthSlice', 'changePassword']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const verifyToken = createAsyncThunk<boolean | undefined, string, AsyncThunkConfig>(
  'advocateAuth/verifyToken',
  async (token, thunkAPI) => {
    try {
      const response = await api.post<boolean>('/advocate/v0_verify_token', undefined, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response) {
        await saveWebUser({ ...new AuthResult(), token });
      }
      return response;
    } catch (e) {
      logError(e, ['advocateAuthSlice', 'verifyToken']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const advocateAuthSlice = createSlice({
  name: 'advocateAuth',
  initialState,
  reducers: {
    updateAuth: (state, action: PayloadAction<AuthResult>) => {
      state.authResult = action.payload;
      state.currentUser = decodeToken(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, action) => {
        state.authResult = action.payload;
        const currentUser = decodeToken(action.payload);
        setRaygunUser(currentUser);
        state.currentUser = currentUser;
      })
      .addCase(refreshToken.fulfilled, (state, action) => {
        const currentUser = decodeToken(action.payload);
        setRaygunUser(currentUser);
        state.currentUser = currentUser;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        const currentUser = decodeToken(action.payload);
        setRaygunUser(currentUser);
        state.currentUser = currentUser;
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        const currentUser = decodeToken(action.payload);
        setRaygunUser(currentUser);
        state.currentUser = currentUser;
      });
  },
});

export const { updateAuth } = advocateAuthSlice.actions;
