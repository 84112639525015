import { useEffect } from 'react';
import { alertCircleOutline } from 'ionicons/icons';

import { IonButton, IonCol, IonContent, IonGrid, IonIcon, IonRow, IonText, useIonAlert } from '@ionic/react';

import '../../pages/advocate/AdvocateChat.scss';

import { isNative } from '../../helpers/device.helper';
import { useCheckNotification } from '../../hooks';
import { useCapturePostHog } from '../../hooks/useCapturePostHog';
import type { DispatchedAsyncThunk } from '../../models/slice';
import { refreshToken } from '../../pages/advocate/AdvocateAuthSlice';
import {
  getAdvocateAvailableStatus,
  setAdvocateAvailable,
  setAdvocateUnavailable,
} from '../../pages/advocate/AdvocateChatSlice';
import { useAppDispatch, useAppSelector } from '../../store';

const INTERVAL = 30 * 1000;

const getWarningMessage = (
  isBrowserNotificationSupported: boolean,
  hasNotificationPermission: boolean,
  hasNotificationTokenSaved: boolean,
) => {
  if (!isNative() && !isBrowserNotificationSupported) {
    return 'This browser does not support push notifications. ';
  } else if (!hasNotificationPermission) {
    return `Oops! You must update your settings to receive chat requests. ${
      isNative()
        ? 'Please delete the Talking Stick app, then go to the App Store to reinstall the app again. Then open the Talking Stick app and select “Allow” to accept notifications. '
        : 'Please contact your Manager for instructions. '
    }`;
  } else if (!hasNotificationTokenSaved) {
    return 'Push notifications are granted but the token was not properly stored. ';
  }
};

export const AdvocateHomePage = () => {
  const dispatch = useAppDispatch();
  const { capturePostHogCustomEvent } = useCapturePostHog();
  const [present, dismiss] = useIonAlert();
  const { isBrowserNotificationSupported, hasNotificationPermission, hasNotificationTokenSaved } =
    useCheckNotification();
  const advocateIsAvailable = useAppSelector((state) => state.advocateSlice.advocateIsAvailable);
  const authResult = useAppSelector((state) => state.advocateAuthSlice.authResult);
  const cannotReceivePushNotifications =
    (!isNative() && !isBrowserNotificationSupported) || !hasNotificationPermission || !hasNotificationTokenSaved;

  useEffect(() => {
    const outer = dispatch(getAdvocateAvailableStatus());
    let inner: DispatchedAsyncThunk<boolean>;
    const intervalId = setInterval(() => {
      inner = dispatch(getAdvocateAvailableStatus());
    }, INTERVAL);

    return () => {
      clearInterval(intervalId);
      outer?.abort();
      inner?.abort();
    };
  }, [dispatch]);

  const onChangeAvailabeStatus = async () => {
    if (cannotReceivePushNotifications) {
      present({
        header: 'Notifications Issue',
        message: '<p>You cannot update your online status because you cannot receive chat requests.<p>',
        buttons: [
          {
            text: 'OK',
            handler: () => {
              capturePostHogCustomEvent('AdvocateHomePage dismissed notification issue alert');
              dismiss();
            },
          },
        ],
      });
    } else if (!advocateIsAvailable && authResult.internal) {
      present({
        header: 'Cannot go online',
        message: '<p>Your account is not enabled to go online. Speak to your manager about enabling this account.<p>',
        buttons: [
          {
            text: 'OK',
            handler: () => {
              capturePostHogCustomEvent('AdvocateHomePage dismissed "cannot go online" alert');
              dismiss();
            },
          },
        ],
      });
    } else {
      const response = await dispatch(refreshToken());
      if (refreshToken.fulfilled.match(response) && response.payload.token) {
        if (advocateIsAvailable) {
          await dispatch(setAdvocateUnavailable());
        } else {
          await dispatch(setAdvocateAvailable());
        }
        await dispatch(getAdvocateAvailableStatus());
        capturePostHogCustomEvent('AdvocateHomePage changed available status', {
          available_status: advocateIsAvailable,
        });
      }
    }
  };

  return (
    <IonContent fullscreen>
      <IonGrid class="page-grid-align-vertical">
        <IonRow class="ion-justify-content-center ion-align-items-center page-grid-align-vertical ">
          <IonCol sizeSm="4" size="12">
            {cannotReceivePushNotifications && (
              <IonRow className="io-margin ion-justify-content-center advocate-notification-status">
                <IonCol size="1">
                  <IonIcon icon={alertCircleOutline} />
                </IonCol>
                <IonCol size="11">
                  <IonText>
                    {getWarningMessage(
                      isBrowserNotificationSupported,
                      hasNotificationPermission,
                      hasNotificationTokenSaved,
                    )}
                    {isNative() && <b>If you continue to have issues, please contact your Manager.</b>}
                  </IonText>
                </IonCol>
              </IonRow>
            )}
            <IonRow className="ion-margin ion-justify-content-center advocate-home-name">
              <IonText className="headline-large text-bold">Hi, {authResult.first_name}</IonText>
            </IonRow>
            <IonRow className="ion-margin ion-justify-content-center">
              <IonText className="title-large">
                {/* We keep "online" just for advocate front-end view */}
                Your status is <span className="text-bold">{advocateIsAvailable ? 'online.' : 'offline.'}</span>
              </IonText>
            </IonRow>
            <IonRow className="ion-margin ion-padding ion-justify-content-center ion-text-center">
              <IonText className="ion-padding title-large text-italic">
                {advocateIsAvailable
                  ? 'You are waiting to receive chat requests.'
                  : 'You will not receive any chat requests.'}
              </IonText>
            </IonRow>
            <IonRow className="ion-margin ion-justify-content-center">
              <IonCol>
                <IonButton
                  className={`${advocateIsAvailable ? 'defaultButtonRed ' : 'defaultButtonBlue'}`}
                  mode="ios"
                  expand="block"
                  shape="round"
                  onClick={onChangeAvailabeStatus}
                >
                  {/* We keep "online" just for advocate front-end view */}
                  Change status to {advocateIsAvailable ? 'offline' : 'online'}
                </IonButton>
              </IonCol>
            </IonRow>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  );
};
