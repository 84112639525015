import type { AxiosError } from 'axios';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { api } from '../../api';
import { environment } from '../../environment/environment';
import type { Advocate } from '../../models/advocate';
import { GUEST_COMPONENT } from '../../models/chat';
import type { ErrorMessage } from '../../models/Error';
import type { AsyncThunkConfig } from '../../models/slice';
import { RaygunErrorHandlerService } from '../../services/raygun';
import type { FindAdvocatesBody } from './lepsSlice';

const { logError } = RaygunErrorHandlerService();

type ServicesSliceType = {
  servicesPageStatus: GUEST_COMPONENT;
  serviceProviders: Advocate[];
  selectedServiceProvider: Advocate | undefined;
  searchServiceProvidersLoading: boolean;
  showServicesTabBar: boolean;
};

const initialState: ServicesSliceType = {
  servicesPageStatus: GUEST_COMPONENT.ADVOCATE_SELECT,
  selectedServiceProvider: undefined,
  serviceProviders: [],
  searchServiceProvidersLoading: false,
  showServicesTabBar: true,
};

export const searchServiceProviders = createAsyncThunk<Advocate[], undefined, AsyncThunkConfig>(
  'services/searchServiceProviders',
  async (_, thunkAPI) => {
    try {
      const body: FindAdvocatesBody = {
        community_ids: [],
        languages: [],
        for_service_providers: true,
        elm_app_major_version: environment.versionNumber.split('.')[0],
        elm_app_version: environment.versionNumber,
      };
      const response = await api.post<Advocate[]>('/guest/v1_search_advocates', body);
      return response ?? [];
    } catch (e) {
      logError(e, ['servicesSlice', 'searchServiceProviders']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const servicesSlice = createSlice({
  name: 'services',
  initialState,
  reducers: {
    setServicesPageStatus: (state, action: PayloadAction<GUEST_COMPONENT>) => {
      state.servicesPageStatus = action.payload;
    },
    setSelectedServiceProvider: (state, action: PayloadAction<Advocate | undefined>) => {
      state.selectedServiceProvider = action.payload;
    },
    setShowServicesTabBar: (state, action: PayloadAction<boolean>) => {
      state.showServicesTabBar = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchServiceProviders.pending, (state, _action) => {
        state.searchServiceProvidersLoading = true;
      })
      .addCase(searchServiceProviders.fulfilled, (state, action) => {
        state.serviceProviders = action.payload;
        state.searchServiceProvidersLoading = false;
      });
  },
});

export const { setServicesPageStatus, setSelectedServiceProvider, setShowServicesTabBar } = servicesSlice.actions;
