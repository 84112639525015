import { useEffect, useMemo, useState } from 'react';

import type { CommunicationUserIdentifier } from '@azure/communication-common';
import type { ChatAdapter } from '@azure/communication-react';
import { createAzureCommunicationChatAdapter, fromFlatCommunicationIdentifier } from '@azure/communication-react';
import type { PluginListenerHandle } from '@capacitor/core';
import { isPlatform } from '@ionic/react';

import { createAutoRefreshingCredential } from '../api/chat';
import { isNative } from '../helpers/device.helper';
import type { ChatUserConn } from '../models/chat';
import { RaygunErrorHandlerService } from '../services/raygun';

const { logError } = RaygunErrorHandlerService();
const NUMBER_OF_MESSAGES_TO_LOAD = 50;

export const useCreateChatAdapter = (chatUserConn: ChatUserConn | undefined) => {
  const chatAdapterArgs = useMemo(() => {
    if (chatUserConn?.threadId) {
      return {
        endpoint: chatUserConn.endpointUrl,
        userId: fromFlatCommunicationIdentifier(chatUserConn.communicationUserId) as CommunicationUserIdentifier,
        displayName: chatUserConn.displayName,
        credential: createAutoRefreshingCredential(chatUserConn.token),
        threadId: chatUserConn.threadId,
      };
    }
  }, [
    chatUserConn?.communicationUserId,
    chatUserConn?.displayName,
    chatUserConn?.endpointUrl,
    chatUserConn?.threadId,
    chatUserConn?.token,
  ]);
  const [chatAdapter, setChatAdapter] = useState<ChatAdapter>();

  useEffect(() => {
    (async () => {
      if (chatAdapterArgs) {
        try {
          const adapter = await createAzureCommunicationChatAdapter(chatAdapterArgs);
          setChatAdapter(adapter);
        } catch (error) {
          logError(error, ['useCreateChatAdapter', 'createAzureCommunicationChatAdapter']);
        }
      } else {
        setChatAdapter((prevChatAdapter) => {
          prevChatAdapter?.dispose();
          return undefined;
        });
      }
    })();
  }, [chatAdapterArgs]);

  // Reloading chat data after background mode for iOS
  // https://github.com/Azure/communication-ui-library/issues/1720
  useEffect(() => {
    let appListener: PluginListenerHandle;

    if (chatAdapter && isNative() && isPlatform('ios')) {
      (async () => {
        const { App } = await import('@capacitor/app');
        appListener = await App.addListener('appStateChange', async ({ isActive }) => {
          if (isActive) {
            try {
              await chatAdapter.fetchInitialData();
              await chatAdapter.loadPreviousChatMessages(NUMBER_OF_MESSAGES_TO_LOAD);
            } catch (error) {
              logError(error, ['useCreateChatAdapter', 'reloading chat data']);
            }
          }
        });
      })();
    }

    return () => {
      appListener?.remove();
    };
  }, [chatAdapter]);

  return chatAdapter;
};
