import { api } from '.';

import { RaygunErrorHandlerService } from '../services/raygun';

const { logError } = RaygunErrorHandlerService();

export const saveNotificationToken = async (notification_token: string) => {
  try {
    const response = await api.post<string>('/guest/v0_save_notification', {
      notification_token,
    });
    return response; // guest_id
  } catch (error) {
    logError(error, ['api/notification', 'saveNotificationToken']);
  }
};

export const hasNotificationToken = async () => {
  try {
    const response = await api.post<boolean>('/chat/v0_has_notification_token');
    return response ?? false;
  } catch (error) {
    logError(error, ['api/notification', 'hasNotificationToken']);
    return false;
  }
};

export const logAlertNotificationRead = async (alert_id: string) => {
  try {
    const response = await api.post<boolean>('/guest/v0_log_alert_read', { alert_id });
    return response ?? false;
  } catch (error) {
    logError(error, ['api/notification', 'logAlertNotificationRead']);
    return false;
  }
};
