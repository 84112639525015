export const environment = {
  baseEndpoint: 'https://api-dev.talkingstick.app/',
  buildNumber: "28777862",  
  versionNumber: "2.1.7",  
  environment_deploy: "dev",  
  rayGunKey: 'hqaZDpkENyp0WOVqpDhdg',  
  authResultKey: '023eecea-1a20-4535-8dde-ec7de07e83b4',  
  cryptoKey: 'eeb99d3b-9bc1-4bd8-b9d5-fd175fb7948b',  
  posthogToken: 'phc_FxfNWKWqYxa9nm24KsWccHzMR776akapxZWqhHjFTGJ',  
  orgCode: '',

  firebaseConfig: {
  apiKey: 'AIzaSyCLDHbBwV_P4NaoQKLKDw5YFHUpUmVD_14',  
  authDomain: 'talking-stick-dev-f04da.firebaseapp.com',  
  projectId: 'talking-stick-dev-f04da',  
  storageBucket: 'talking-stick-dev-f04da.appspot.com',  
  messagingSenderId: '777694147085',  
  appId: '1:777694147085:web:c35f67b3a28e2514295b44',  
  vapidKey: 'BMkP8WV0peP5ZBQbr6jiFatOC2NLUWBd-IvJrlYYBzD6yqRHUXaE2S_5ftt2yD7el7krAYvinfMfzAKWiy_IcHU',  
  },
};
