import { useEffect } from 'react';
import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router';

import type { PluginListenerHandle } from '@capacitor/core';

import { isNative } from './helpers/device.helper';
import { verifyToken } from './pages/advocate/AdvocateAuthSlice';
import { logout, useAppDispatch } from './store';

const AppUrlListener = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const navigateWithToken = useCallback(
    async (token: string) => {
      const response = await dispatch(verifyToken(token));
      if (verifyToken.fulfilled.match(response) && response.payload) {
        history.push(`/advocate-reset-password/${token}`);
      } else {
        await logout(dispatch);
        history.push('/advocate-login');
      }
    },
    [dispatch, history],
  );

  useEffect(() => {
    let appListener: PluginListenerHandle;

    if (!isNative()) {
      const webToken = location.pathname.includes('advocate-reset-password/')
        ? location.pathname.split('advocate-reset-password/').pop()
        : undefined;
      if (webToken) {
        navigateWithToken(webToken);
      }
    } else {
      (async () => {
        const { App } = await import('@capacitor/app');
        appListener = await App.addListener('appUrlOpen', ({ url }) => {
          const token = url.split('advocate-reset-password/')[1];
          if (token) {
            navigateWithToken(token);
          }
        });
      })();
    }

    return () => {
      appListener?.remove();
    };
  }, [location.pathname, navigateWithToken]);

  return null;
};

export default AppUrlListener;
