import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';

import { environment } from '../environment/environment';
import { CryptoService } from '../services/crypto';

const THREAD_ID_KEY = '007406ee-615f-4187-bc3d-d989026b73c3';

export const getGuestThreadId = async () => {
  if (!Capacitor.isPluginAvailable('Preferences')) {
    return;
  }

  const { value: encrypted } = await Preferences.get({ key: THREAD_ID_KEY });
  if (encrypted) {
    const decrypted = await CryptoService.decrypt(encrypted, environment.cryptoKey, 'getGuestThreadId');
    return decrypted;
  }
};

export const saveGuestThreadId = async (thread_id: string) => {
  if (!Capacitor.isPluginAvailable('Preferences')) {
    return;
  }

  const encrypted = await CryptoService.encrypt(thread_id, environment.cryptoKey, 'saveGuestThreadId');
  await Preferences.set({
    key: THREAD_ID_KEY,
    value: encrypted,
  });
};

export const removeGuestThreadId = async () => {
  if (!Capacitor.isPluginAvailable('Preferences')) {
    return;
  }

  await Preferences.remove({ key: THREAD_ID_KEY });
};
