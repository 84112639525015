import { jwtDecode } from 'jwt-decode';

import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';

import { environment } from '../environment/environment';
import type { TokenValue } from '../pages/advocate/AdvocateAuthSlice';
import { AuthResult } from '../pages/advocate/AdvocateAuthSlice';
import { CryptoService } from './crypto';
import { RaygunErrorHandlerService } from './raygun';

const { logError } = RaygunErrorHandlerService();

export const getWebUserAsync = async () => {
  if (!Capacitor.isPluginAvailable('Preferences')) {
    return new AuthResult();
  }

  const ret = await Preferences.get({ key: environment.authResultKey });
  const cryptedAuthResult = ret.value;
  if (cryptedAuthResult) {
    try {
      const decrypted = await CryptoService.decrypt(cryptedAuthResult, environment.cryptoKey, 'getWebUserAsync');
      const authResult: AuthResult = JSON.parse(decrypted);
      return authResult?.token ? authResult : new AuthResult();
    } catch (error) {
      logError(error, ['service/login', 'getWebUserAsync']);
      return new AuthResult();
    }
  }
  return new AuthResult();
};

export const removeWebUser = async () => {
  if (!Capacitor.isPluginAvailable('Preferences')) {
    return;
  }

  await Preferences.remove({ key: environment.authResultKey });
};

export const saveWebUser = async (user: AuthResult) => {
  if (!Capacitor.isPluginAvailable('Preferences')) {
    return;
  }

  if (user?.token) {
    const cryptedAuthResult = await CryptoService.encrypt(JSON.stringify(user), environment.cryptoKey, 'saveWebUser');
    await Preferences.set({
      key: environment.authResultKey,
      value: cryptedAuthResult,
    });
  }
};

export const decodeToken = (authResult: AuthResult) => {
  try {
    if (authResult?.token) {
      return jwtDecode<TokenValue>(authResult.token);
    }
  } catch (error) {
    logError(error, ['service/login', 'decodeToken']);
  }
};
